
@font-face {
  font-family: AzoSans-Medium;
  src: url('../assets/fonts/AzoSans-Medium.otf');
}

@font-face {
  font-family: AzoSans-Bold;
  src: url('../assets/fonts/AzoSans-Bold.otf');
}

@font-face {
  font-family: AzoSans-Black;
  src: url('../assets/fonts/AzoSans-Black.otf');
}

@font-face {
  font-family: AzoSans-Regular;
  src: url('../assets/fonts/AzoSans-Regular.otf');
}

@font-face {
  font-family: MyriadPro-Regular;
  src: url('../assets/fonts/MyriadPro-Regular.otf');
}

/* MAPA */
.mapa {
  width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.mapa:not(.expanded) {
  max-height: 100vh;
}

.mapa .map-title {
  max-width: 22rem;
  position: absolute;
  left: 3.2vw;
  top: 30%;
}

.map-title h1 {
  font-family: "AzoSans-Black", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 48px;
  letter-spacing: -1px;
  line-height: 47px;
  margin-top: 0;
  margin-bottom: 10px;
  display: none;
}

.map-title h1 span {
  font-size: 37px;
}

.map-title .rota-logo {
  width: 17.5rem;
  margin-bottom: 16px;
}

.map-title p {
  font-family: "MyriadPro-Regular", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  padding-left: 5.65rem;
  margin-bottom: 5px;
}

.map-title p.bolder {
  max-width: 20rem;
  font-size: 17px;
  line-height: 22px;
  font-weight: 700;
}



.map-container.grabbing {
  cursor: grabbing;
}

.popup-wrapper {
  max-height: max-content;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  opacity: 0;
  z-index: -1;
}

.popup-wrapper.active {
  opacity: 1;
  z-index: 5;
  transition: opacity 0.4s  ease;
  -webkit-transition: opacity 0.4s ease;
  -moz-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
}

.mkt-popup {
  height: 120px;
  max-width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1a325d;
  box-shadow: 0px 7px 8px rgb(0 0 0 / 25%);
  cursor: pointer;
}

.mkt-popup:nth-of-type(even) {
  flex-direction: row-reverse;
  margin-top: 10px;
}

.mkt-popup img, .mkt-popup .brief-info {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mkt-popup img {
  width: 120px;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
}

.mkt-popup .brief-info {
  max-width: 262px;
  padding: 0 35px 0 15px;
}

.mkt-popup .brief-info span, .mkt-popup .brief-info p {
  color: #fff;
}

.mkt-popup .brief-info span {
  font-size: 13px;
}

.mkt-popup .brief-info p {
  font-family: "AzoSans-Bold", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 19px;
  margin-bottom: 0;
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
}

/* CONTEUDOS SOBRE MERCADO */
.mkt-about {
  height: 0;
  overflow: hidden;
  margin: 0 6vw 0 7vw;
  position: relative;
  z-index: 2;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
}

.mkt-about.open {
  margin-bottom: 50px;
}

.mkt-about .about-wrapper {
  display: grid;
  grid-template-columns: calc(45% - 27.5px) calc(55% - 27.5px);
  grid-column-gap: 55px;
  position: relative;
}

.mkt-location {
  font-family: "AzoSans-Medium", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-weight: 100;
  font-size: 19px;
  margin-top: 0;
  margin-bottom: 13px;
  grid-column-start: 2;
  grid-row-start: 1;
}

.mkt-header {
  grid-column-start: 2;
  grid-row-start: 2;
}

.mkt-gallery, .mkt-thumbnails, .mkt-thumbnails img {
  width: 100%;
}

.mkt-gallery, .mkt-thumbnails, .mkt-gal-ctrl {
  display: flex;
}

.mkt-gallery {
  grid-column-start: 1;
  grid-row-start: 2;
  grid-row-end: 4;
  flex-direction: column;
  align-items: flex-start;
}

.mkt-thumbnails {
  height: 26.5vw;
  min-height: 250px;
  flex-direction: row;
  overflow: hidden;
}

.mkt-thumbnails img {
  height: 100%;
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.mkt-gal-ctrl {
  flex-direction: row;
  margin-top: 25px;
}

.mkt-gal-ctrl .ctrl {
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 40px;
  background-color: #edede9;
}

.mkt-gal-ctrl .ctrl.active {
  background-color: #d1d1ce;
}

.mkt-gal-ctrl .ctrl:not(:last-of-type) {
  margin-right: 8px;
}

.mkt-gal-ctrl .ctrl:only-of-type {
  display: none;
}

.mkt-content{
  grid-row-start: 3;
  grid-column-start: 2;
}

h2.mkt-name {
  font-family: "AzoSans-Bold", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 45px;
  max-width: 550px;
  margin: -5px 0 0 0;
}

.loc-ret {
  fill: transparent;
}

.loc-ret.open {
  fill: #1c3359;
}

.mkt-locale.open {
  fill: #fff;
}

.mkt-content {
  display: grid;
  grid-template-columns: 65% 35%;
  margin-top: 70px;
}

.mkt-content .mkt-col-1 {
  padding-right: 30px;
}

.mkt-content .cell {
  margin-bottom: 25px;
}

.sec-title {
  font-family: "AzoSans-Bold", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 15px;
  letter-spacing: 0.2px;
  margin: 0;
}

.sec-text, .mkt-popup .brief-info span {
  font-family: "AzoSans-Regular", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  letter-spacing: 0.2px;
  margin: 0;
}

.loc-bttn {
  font-family: "AzoSans-Medium", system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 13px;
  letter-spacing: 0.5px;
  text-decoration: none;
  position: relative;
  padding: 6px 0px 3px ;
}

.loc-bttn:before, .loc-bttn:after, .close-mkt:before, .close-mkt:after {
  content: '';
  position: absolute;
  background-color: #1a325d;
  width: calc(100% + 16px);
  left: -8px;
  bottom: -4px;
}

.loc-bttn:hover:after, .loc-bttn:after, .loc-bttn, .loc-bttn:hover, .close-mkt:after, .close-mkt:hover:after, .close-mkt, .close-mkt:hover, .mkt-thumbnails img {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.loc-bttn:before, .close-mkt:before {
  height: 2px; 
}

.loc-bttn:after, .close-mkt:after {
  transform: scaleY(0);
  transform-origin: bottom;
  z-index: -1;
}

.loc-bttn:after {
  height: calc(100% + 6px);
}

.close-mkt:after {
  height: calc(100% + 14px);
}

.loc-bttn:hover, .close-mkt:hover {
  color: #fff;
}

.loc-bttn:hover:after, .close-mkt:hover:after {
  transform: scaleY(1);
}

/* VOLTAR AO MAPA */
.close-mkt-cont {
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 85px;
  padding-right: 1vw;
  position: relative;
}

.close-mkt-cont:before {
  content: url(../assets/images/blue-arrow.svg);
  position: absolute;
  width: 44px;
  top: -46px;
  left: calc(50% - 17px - 0.5vw);
}

.close-mkt {
  font-family: "AzoSans-Regular", system-ui, -apple-system,BlinkMacSystemFont, "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 25px;
  letter-spacing: 2px;
  background-color: transparent;
  padding: 0;
  margin: 0 auto 5px auto;
  display: block;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.close-mkt:focus {
  outline: none;
}

.rota-logos {
  background-color: #f8faee;
  position: relative;
  margin-top: -6rem;
  z-index: 2;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
}

.logos-wrapper {
  width: 38rem;
  margin: 0 auto;
  padding: 2rem 0 3.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.logos-wrapper img {
  height: 5rem;
}

.mkt-about.open + .rota-logos {
  background-color: transparent;
  margin-top: -1rem;
}

/* responsive */
@media (max-width: 1350px) {
  .mapa .map-title {
    left: 5.5vw;
  }

  .mkt-about {
    margin-left: 5.5vw;
    margin-right: 5.5vw;
  }
}


@media (max-width: 1300px) {
  .mapa .map-title {
    top: 20%;
    left: 3vw;
  }

  .mkt-content {
    grid-template-columns: 60% 40%;
  }
}


@media (max-width: 1250px) {
  .map-title p {
    font-size: 14px;
    line-height: 19px;
  }

  .map-title p.bolder {
    max-width: 307px;
    font-size: 16px;
    line-height: 21px;
  }

  .mkt-about .about-wrapper {
    grid-column-gap: 40px;
    grid-template-columns: calc(45% - 20px) calc(55% - 20px);
  }
}


@media (max-width: 1150px) {
  .mapa .map-title {
    left: 2vw;
  }
  
  .map-title h1 {
    font-size: 44px;
    line-height: 45px;
  }

  .map-title h1 span {
    font-size: 34px;
  }
}


@media (max-width: 1024px) {
  /* map */
  .mapa .map-title {
    max-width: 24rem;
    top: 55px;
    left: 3vw;
    z-index: 2;
    pointer-events: none;
  }

  .map-title p br {
    display: none;
  }

  .map-container {
    width: 100%;
    overflow-x: hidden;
    position: relative;
    cursor: grab;
  }
  
  .map-wrapper {
    min-width: 1100px;
    margin-left: -260px;
  }

  .map-wrapper:before, .map-wrapper:after {
    content: '';
    position: relative;
    display: block;
    background-color: #f8faef;
  }
  
  .map-wrapper:before {
    height: 170px;
  }

  .map-wrapper:after {
    height: 20px;
  }


  /* market details */
  .mkt-about .about-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .mkt-gallery {
    order: 2;
    margin-top: 25px;
  }
  
  .mkt-thumbnails {
    height: 64vw;
  }
  
  .mkt-header {
    order: 1;
  }
  
  h2.mkt-name {
    font-size: 41px;
    line-height: 45px;
    margin: -3px 0 0 0;
  }
  
  .mkt-content {
    order: 3;
    margin-top: 40px;
  }
  
  .close-mkt-cont {
    order: 4;
  }

  .close-mkt-cont:before {
    width: 32px;
    top: -41px;
    left: calc(50% - 16px);
  }

  .close-mkt {
    font-size: 17px;
    letter-spacing: 1.5px;
  }

  /* logos */
  .logos-wrapper {
    width: 33rem;
  }

  .logos-wrapper img {
    height: 4.5rem;
  }
}

@media (max-width: 900px) {
  .mapa .map-title {
    left: 6vw;
  }

  .sec-title {
    font-size: 14px;
  }

  .sec-text {
    font-size: 15px;
  }
}


@media (max-width: 850px) {
  .map-wrapper:before {
    height: 190px;
  }

  .mkt-gal-ctrl {
    margin-top: 18px;
  }

  .mkt-gal-ctrl .ctrl {
    width: 16px;
    height: 16px;
  }

  .mkt-gal-ctrl .ctrl:not(:last-of-type) {
    margin-right: 6px;
  }

  .logos-wrapper {
    width: 31rem;
  }

  .logos-wrapper img {
    height: 4rem;
  }
}

@media (max-width: 600px) {
  .map-wrapper:before {
    height: 220px;
  }

  .map-title p {
    padding-left: 4px;
  }

  .map-title p:first-of-type {
    max-width: 17rem;
  }

  .mkt-popup .brief-info p {
    font-size: 17px;
    line-height: 22px;
    margin-top: 4px;
  } 

  .mkt-location {
    font-size: 16px;
    margin-bottom: 11px;
  }

  h2.mkt-name {
    font-size: 35px;
    line-height: 39px;
  }

  .mkt-content {
    display: flex;
    flex-direction: column;
  }

  .mkt-content .mkt-col-1 {
    padding: 0;
  }

  .loc-bttn {
    margin-left: 8px;
  }

  .close-mkt-cont {
    margin-top: 90px;
  }
}

@media (max-width: 500px) {
  .map-wrapper {
    margin-left: -280px;
  }

  .mkt-popup {
    height: 105px;
    
    max-width: 320px;
  }

  .mkt-popup img {
    width: 105px;
  }

  .mkt-popup .brief-info span {
    font-size: 12px;
  }

  .mkt-popup .brief-info p {
    font-size: 15px;
    line-height: 20px;
  }

  .mkt-location {
    font-size: 15px;
  }

  h2.mkt-name {
    font-size: 32px;
    line-height: 35px;
  }

  .mkt-content .cell {
    margin-bottom: 17px;
  }

  .sec-title {
    font-size: 13px;
  }
  
  .sec-text {
    font-size: 14px;
  }

  .close-mkt-cont:before {
    width: 28px;
    top: -36px;
    left: calc(50% - 14px);
  }

  .close-mkt {
    font-size: 15px;
  }

  /* logos */
  .logos-wrapper {
    width: 19rem;
    flex-wrap: wrap;
  }

  .mkt-about.open + .rota-logos {
    margin-top: -2.5rem;
  }

  .logos-wrapper img#logo-rc {
    margin: 0 2rem 1rem 2rem;
  }
}


@media (max-width: 400px) {
  h2.mkt-name {
    font-size: 30px;
    line-height: 32px;
  }
}

@media (max-width: 310px) {
  .logos-wrapper {
    width: 100%;
  }

  .logos-wrapper img {
    height: 21vw;
  }
}